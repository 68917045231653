<template>
  <a-select
    mode="multiple"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Area"
    allow-clear
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="() => (findText = null)"
    @select="() => (findText = null)"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'

export default {
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    propinsi: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const data = ref([])
    const findText = ref(null)
    const propinsi = toRefs(props).propinsi

    const fetchData = provinsi => {
      if (provinsi.length <= 0) provinsi = undefined
      apiClient.get('/api/filter/area-by-role', { params: { provinsi } }).then(response => {
        const sorted = response.data
        data.value = sorted.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      })
    }

    onMounted(() => {
      fetchData(propinsi.value)
    })

    watch(propinsi, (after, before) => {
      console.log('region changed')
      fetchData(propinsi.value, after)
    })

    const onSearch = value => {
      findText.value = value
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return { data, valueState: useVModel(props, 'value'), findText, highlight, onSearch }
  },
}
</script>

<style></style>
