<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Satuan"
    allow-clear
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    :show-arrow="true"
  >
    <a-select-option v-for="d in dataRegional" :key="d[modelId]" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      default: 'multiple',
      type: String,
    },
    modelId: { type: String, default: 'id' },
  },
  emits: ['update:value'],
  setup(props) {
    const dataRegional = ref([])
    const findText = ref(null)

    const fetchData = () => {
      apiClient.get('/api/filter/uom').then(response => {
        dataRegional.value = response.data.map(d => ({ id: d.uom, name: d.uom }))
      })
    }

    onMounted(() => {
      fetchData()
    })

    const onSearch = value => {
      findText.value = value
    }

    const highlight = value => {
      if (value === undefined) return
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return { dataRegional, valueState: useVModel(props, 'value'), findText, highlight, onSearch }
  },
}
</script>

<style></style>
